<template>
    <teleport to="head">
        <component :is="'script'" type="application/ld+json">
            {
            "@context": "https://schema.org",
            "@type": "NewsArticle",
            "url": "{{ newsDetail.urlName }}",
            "publisher":{
            "@type":"Organization",
            "name":"AsiaSport News",
            "logo":"https://asiasport.com/img/icons/favicon-32x32.png"
            },
            "headline": "{{ newsDetail.title }}",
            "mainEntityOfPage": "{{ newsDetail.urlName }}",
            "articleBody": "{{ newsDetail.contentOnly }}",
            "image": {{ newsDetail.contentImgArray }},
            "datePublished":"{{ newsDetail.originalPublishedOn }}",
            "author":{
            "@type" : "Person",
            "name" : "{{ newsDetail.authorName }}"
            }
            }
        </component>
    </teleport>
    <MobileHeader></MobileHeader>

    <div class="container">
        <div class="news-detail__wrapper has-header has-space">
            <div class="news-detail__container">
                <h1 class="news-detail__title">{{ newsDetail.title }}</h1>
                <div class="news_detail__author-header__wrapper">
                    <router-link :to="{ name: 'newsAuthor', params: { authorName: newsDetail.urlAuthorName, id: newsDetail.author } }" class="news_detail__author-wrapper">
                        <img class="news_detail__author-icon" src="../../../static/images/icons/icon_news_author.png">
                        <div class="news_detail">
                            <span class="news_detail__author-name">{{ $t('BY') }} {{ newsDetail.authorName }}</span>
                            <div>
                                <span class="news_detail__author-tag">{{ newsDetail.publishedOn }}</span>
                            </div>
                        </div>
                    </router-link>
                    <div class="news_detail__author-header__social-media">
                        <div class="news_detail__author-header__social-media__container" v-for="(o, index) in socialMediaShare" :key="index" @click="handleSocialMedia(o)">
                            <img :src="require(`../../../static/images/icons/social_media/${o.iconUrl}.png`)">
                        </div>
                    </div>
                </div>

                <!-- content -->
                <div class="mb-3rem">
                    <div id="news" class="news-detail__content" v-html="newsDetail.content"></div>
                </div>

                <!-- tags -->
                <div class="news-detail__tags-wrapper">
                    <div class="news-detail__tags-title__wrapper">
                        <span>{{ $t('TAGS') }}</span>
                    </div>
                    <div class="">
                        <router-link :to="{ name: 'newsRelated', params: { categoryName: o.urlName, categoryId: o.id }, query: {language: newsDetailLanguage, sportType: o.sportTypePath} }" 
                        v-for="(o, index) in newsDetail.newsTags" 
                        :key="index"
                         class="news-detail__tags">{{ o.name }}</router-link>
                    </div>
                </div>

                <!-- comment -->
                <div class="mb-main" v-if="!isMobile">
                    <comment :isGetData="refreshComment"></comment>
                </div>
            </div>

            <div class="related-articles__container" v-show="relatedArticleList.length > 0">
                <div class="news_latest-news__title-wrapper">
                    <span class="news_latest-news__title">{{ $t('RELATED_ARTICLES') }}</span>
                </div>
                <!-- //related articles -->
                <div v-if="!isMobile">
                    <router-link :to="{ name: 'newsDetail', params: { title: o.urlName, id: o.id, currentSportType: o.sportTypePath } }" class="news_latest-news__container" v-for="(o, index) in relatedArticleList" :key="index">
                        <div class="news_latest-news__container-img">
                            <img :src="`${s3ImgUrl}/${o.coverImage}`" class="news_latest-news__container-img__coverImg">
                            <div class="news_latest-news__container-img-icon" v-if="o.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
                        </div>
                        <div class="news_latest-news__container-desc">
                            <p class="news_latest-news__container-desc__title">{{ o.title }}</p>
                            <div>
                                <span class="news_latest-news__container-desc_tag">{{ o.publishedOn }}</span>
                            </div>
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <swiper :pagination="{ clickable: true }" :slidesPerView="2" :autoplay='{ "delay": 5000, "disableOnInteraction": false }' :spaceBetween="12" class="relative news__related-articles__swipper">
                        <swiper-slide class="news_latest-news__container" v-for="(o, index) in relatedArticleList" :key="index">
                            <router-link :to="{ name: 'newsDetail', params: { title: o.urlName, id: o.id, currentSportType: o.sportTypePath } }">
                                <div class="news_latest-news__container-img">
                                    <img :src="`${s3ImgUrl}/${o.coverImage}`" class="news_latest-news__container-img__coverImg">
                                    <div class="news_latest-news__container-img-icon" v-if="o.videoFlag"><img src="../../../static/images/icons/icon_video_play.png"></div>
                                </div>
                                <div class="news_latest-news__container-desc">
                                    <p class="news_latest-news__container-desc__title">{{ o.title }}</p>
                                    <div>
                                        <span class="news_latest-news__container-desc_tag">{{ o.publishedOn }}</span>
                                    </div>
                                </div>
                            </router-link>
                        </swiper-slide>
                    </swiper>
                    <!-- comment -->
                    <div class="mb-main">
                        <comment :isGetData="refreshComment"></comment>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ToastrDesktop ref="toastrDesktop" :text="'LINK_COPIED'"></ToastrDesktop>
    <!-- <Toastr ref="toastr" :text="'LINK_COPIED'" ></Toastr>  -->
</template>

<script>
import moment from 'moment'

import config from '@/js/config.js'
import MobileHeader from '@/components/mobile/Header.vue'
import ToastrDesktop from '@/components/toastr/ToastrDesktop.vue'
import Toastr from '@/components/toastr/Toastr.vue'
import Comment from '@/components/comment/Index.vue'

import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper/core';
SwiperCore.use([Pagination, Navigation, Autoplay]);

import { mapGetters, mapActions } from 'vuex'
import { offset } from '@popperjs/core'

import removeTags from '@/js/helpers/removetags';
import { nextTick } from 'vue'

export default {
    components: {
        Swiper,
        SwiperSlide,
        MobileHeader,
        ToastrDesktop,
        Toastr,
        Comment
    },
    data() {

        return {
            projectName: config.projectName,
            s3ImgUrl: config.s3ImgUrl,
            socialMediaShare: [
                {
                    id: "fb",
                    socialMediaUrl: 'https://www.facebook.com/sharer/sharer.php?u=',
                    iconUrl: 'icon_fb'
                },
                {
                    id: "twitter",
                    socialMediaUrl: 'https://twitter.com/share?url=',
                    iconUrl: 'icon_twitter'
                },
                {
                    id: "line",
                    socialMediaUrl: 'https://social-plugins.line.me/lineit/share?url=',
                    iconUrl: 'icon_line'
                },
                {
                    id: "selfShare",
                    socialMediaUrl: "",
                    iconUrl: 'icon_share'
                }
            ],
            newsDetail: {
                //ssr bugs must initial some value
                urlAuthorName: '1',
                author: '1'
            },

            relatedArticleList: [],
            refreshComment: false,

            newsDetailLanguage: "",
        }
    },
    created() {
        this.newsId = this.$route.params.id;

        this.setMetadataProvider(async () => {
            const params = {
                newsId: this.newsId
            };

            const apiResponse = await this.getNewsDetail(params);
            const result = apiResponse.result;

            const maxContentLength = 128;

            const strippedContent = removeTags(result.content);

            const metadata = {
                title: result.title,
                description: `${strippedContent}`.substring(0, maxContentLength)
            };

            if (strippedContent && strippedContent.length > maxContentLength) {
                metadata.description = metadata.description.concat(`...`);
            }

            return metadata;
        });
    },
    beforeRouteLeave() {
        this.setMetadataProvider(null);
    },
    beforeUnmount() {
        this.removeVisibilityChangeListener();
        this.removeLoadListener();
    },
    watch: {
        $route() {
            if (["newsDetail"].includes(this.$route.name)) {
                this.init();
            }
        },
    },
    computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale',
            'currentSportType',
            'currentLanguageObj',
            'currentSportTypeParam'
        ]),
    },
    mounted() {
        this.init();
        this.addLoadListener();
    },
    methods: {
        ...mapActions([
            "getNewsDetail",
            "getNewsListByTag",
            "toastrChangeEvent",
            "setMetadataProvider"
        ]),

        init() {

            this.getNewsDetailData();
            this.refreshComment = !this.refreshComment;
        },
        getRandomNumberBetween(min, max) {
            return (Math.random() * (max - min + 1) + min);
        },

        // react to stop embedded videos if user switched away from the page / tab
        toggleTabHandler() {
            if (document.visibilityState === "hidden") {
                let newsContent = document.getElementById("news").childNodes;
                for (const ele of newsContent) {
                    let iframes = ele.getElementsByTagName("iframe");
                    for (const iframe of iframes) {
                        iframe.src = iframe.src;
                    }
                }
            }
        },
        addVisibilityChangeListener() {
            document.addEventListener("visibilitychange", this.toggleTabHandler);
        },
        removeVisibilityChangeListener() {
            document.removeEventListener("visibilitychange", this.toggleTabHandler);
        },
        addLoadListener() {
            window.addEventListener("load", this.scrollToCommentSection);
        },
        removeLoadListener() {
            window.removeEventListener("load", this.scrollToCommentSection);
        },
        scrollToCommentSection() {
            if (this.$route.name === "newsDetailScrollTo") {
                let commentSection = document.getElementById(this.$route.params.scrollTo).getBoundingClientRect();
                window.scrollTo({
                    top: commentSection.top,
                    left: 0,
                    behavior: "smooth"
                });
                console.log("this.$route.params ",this.$route.params.scrollTo)
            }
        },

        async getNewsDetailData() {
            let routeName = this.$route.name;
            this.newsId = this.$route.params.id;

            let params = {
                newsId: this.newsId
            }
            const result = await this.getNewsDetail(params);

            if (result.result !== null && (routeName === "bgPreviewNewsDetail" || result.result.status === "PUBLISHED")) {
                this.newsDetail = result.result;
            } else {
                this.$router.replace({ name: "news" });
            }

            this.initNewsDetailData();

            //get related news list data
            this.newsDetailLanguage = this.newsDetail.language;
            this.getNewsListByTagData(this.newsDetail.language);
            this.$nextTick(() => {
                this.addVisibilityChangeListener();

                if(window.innerWidth <= 768) {
                    document.getElementById("news").childNodes.forEach(element => {    
                        if (element.getElementsByTagName("img")[0]) {
                            if (element.getElementsByTagName("img")[0].parentElement.parentElement.style.width !== '') {
                                element.getElementsByTagName("img")[0].parentElement.parentElement.style.width="100%";
                                element.getElementsByTagName("img")[0].parentElement.parentElement.style.height="100%";
                            }
                        }                       
                    });   
                }
            })   
           
        },

        async getNewsListByTagData(language) {

            let newsTags = '';

            for (let i = 0; i < this.newsDetail.newsTags.length; i++) {
                newsTags += `${this.newsDetail.newsTags[i].id},`;
            }

            let params = {
                pageNo: 1,
                pageSize: 10, //requirement from AS-290
                language: language,
                lang: this.currentLanguageObj.apiParam,
                tagIds: newsTags,
                sportType: this.newsDetail.sportType//this.currentSportTypeParam
            }

            const result = await this.getNewsListByTag(params);

            this.relatedArticleList = result.result.content;

            this.relatedArticleList.forEach(x => {
                x.urlName = x.title.toLowerCase().replace(/\s/g, '-')
                x.publishedOn = moment(x.publishedOn).fromNow();

                if (x.sportType == "SOCCER") {
                    x.sportTypePath = "football";
                } else {
                    x.sportTypePath = x.sportType.toLowerCase();
                }
            })
        },

        handleSocialMedia(o) {
            if (o.id === 'selfShare') {
                let value = window.location.href;
                /* Copy the text inside the text field */
                navigator.clipboard.writeText(value);
                if (this.isMobile) {

                    let paramsToastr = {
                        isOpen: true,
                        msg: 'LINK_COPIED',
                        info: ''
                    }
                    this.toastrChangeEvent(paramsToastr)
                    //this.$refs.toastr.handleOpen(); 
                } else {
                    this.$refs.toastrDesktop.handleOpen();
                }
            } else {
                let href = window.location.href
                let url = o.socialMediaUrl + href;

                let windowOptions = "location=yes,height=570,width=420,scrollbars=yes,status=yes";

                //window.open(url,'_blank', windowOptions);

                this.$tools.popupwindow(url, '', 550, 570);

            }
        },

        initNewsDetailData() {
            let banner3d = {
                html: "",
                redirectLink: "",
                imgPath: "/img/newsd3d/high_res/",
                availableLanguage:
                    [
                        {
                            locale: 'th',
                            link: "https://bit.ly/3SleF7w",
                        },
                        {
                            locale: 'km',
                            link: "https://bit.ly/3R1OvWs"
                        }
                    ]
            }

            // for (let i = 0; i < banner3d.availableLanguage.length; i++) {
            //     let item = banner3d.availableLanguage[i];

            //     if (item.locale === this.currentLanguageObj.locale) {

            //         banner3d.html = `
            //             <div>
            //             <a href=${item.link} target="_blank" class="news-detail__3d-wrapper">
            //                 <div class="news-detail__3d-container"><img src="${banner3d.imgPath}3D-Banner-play168-merge-${item.locale}.png"></div>
            //              </a>
            //              </div>
            //             `;
            //         break;
            //     } else {
            //         banner3d.html = ""
            //     }
            // };
            banner3d.html = ""
            this.newsDetail.content = this.newsDetail.content.replaceAll("&lt;3d_effect&gt;", banner3d.html);
            // this.$nextTick(() => {
            //     if (banner3d.html !== "") {
            //         this.init3DEffect();
            //     }
            // })

            document.title = `${this.newsDetail.title} - ${this.projectName}`;

            let metaTitle = document.querySelector("meta[property='og:title']");
            metaTitle.setAttribute('content', this.newsDetail.title);

            this.newsDetail.originalPublishedOn = this.newsDetail.publishedOn;
            //convert published date to xx ago
            this.newsDetail.publishedOn = moment(this.newsDetail.publishedOn).fromNow();

            //full url
            this.newsDetail.fullUrl = window.location.href;

            //author name replace space to '-'
            if (this.newsDetail.authorName) {
                this.newsDetail.urlAuthorName = this.newsDetail.authorName.toLowerCase().replace(/\s/g, '-')
            }

            let theNewsDetail = this.newsDetail;
            //rename the tags name with '-' if the words got space
            this.newsDetail.newsTags.forEach(x => {
                x.urlName = x.name.toLowerCase().replace(/\s/g, '-')
                if (theNewsDetail.sportType == "SOCCER") {
                    x.sportTypePath = "football";
                } else {
                    x.sportTypePath = theNewsDetail.sportType.toLowerCase();
                }
            })

            this.newsDetail.urlName = window.location.href;

            //cover content string = html
            let wordsSplited = this.$tools.removeHTMLTags(this.newsDetail.content).replace(/['"]+/g, '').split(" ");
            wordsSplited.length = 200;  //only take max 200words
            let contentOnly = wordsSplited.join(" ");

            this.newsDetail.contentOnly = contentOnly // only take max 200words

            //find all image src to array for google meta usage
            let div = document.createElement('div');
            div.innerHTML = this.newsDetail.content;
            let img = div.getElementsByTagName('img');
            let imgArray = [];

            for (let i = 0; i < img.length; i++) {
                imgArray.push(img[i].src);
            }

            this.newsDetail.contentImgArray = imgArray;
        },

        init3DEffect() {
            let calculateAngle = function (e, item, parent) {
                let dropShadowColor = `rgba(0, 0, 0, 0.3)`
                if (parent.getAttribute('data-filter-color') !== null) {
                    dropShadowColor = parent.getAttribute('data-filter-color');
                }

                parent.classList.add('animated');
                // Get the x position of the users mouse, relative to the button itself
                let x = Math.abs(item.getBoundingClientRect().x - e.clientX);
                // Get the y position relative to the button
                let y = Math.abs(item.getBoundingClientRect().y - e.clientY);

                // Calculate half the width and height
                let halfWidth = item.getBoundingClientRect().width / 2;
                let halfHeight = item.getBoundingClientRect().height / 2;

                // Use this to create an angle. I have divided by 6 and 4 respectively so the effect looks good.
                // Changing these numbers will change the depth of the effect.
                let calcAngleX = (x - halfWidth) / 6;
                let calcAngleY = ((y - halfHeight) / 14) * -1;

                let gX = (1 - (x / (halfWidth * 2))) * 100;
                let gY = (1 - (y / (halfHeight * 2))) * 100;

                //item.querySelector('.glare').style.background = `radial-gradient(circle at ${gX}% ${gY}%, rgb(199 198 243), transparent)`;
                // And set its container's perspective.
                parent.style.perspective = `${halfWidth * 6}px`
                item.style.perspective = `${halfWidth * 6}px`

                // Set the items transform CSS property
                item.style.transform = `rotateY(${calcAngleX}deg) rotateX(${calcAngleY}deg) scale(1.04)`;
                // parent.querySelector('.inner-card-backface').style.transform = `rotateY(${calcAngleX}deg) rotateX(${-calcAngleY}deg) scale(1.04) translateZ(-4px)`;
                item.querySelector('img').style.transform = `translate3d(${calcAngleX * (-3)}px, ${calcAngleY * (-3)}px, 60px)`;
                if (parent.getAttribute('data-custom-perspective') !== null) {
                    parent.style.perspective = `${parent.getAttribute('data-custom-perspective')}`
                }

                // Reapply this to the shadow, with different dividers
                let calcShadowX = (x - halfWidth) / 3;
                let calcShadowY = (y - halfHeight) / 6;

                // Add a filter shadow - this is more performant to animate than a regular box shadow.
                item.style.filter = `drop-shadow(${-calcShadowX}px ${-calcShadowY}px 15px ${dropShadowColor})`;
            }

            let $card = document.querySelectorAll('.news-detail__3d-wrapper');
            let $btn = document.querySelectorAll(".news-detail__3d-btn");

            let target2 = $card[0]
            let target2Child = $card[0];

            if (!this.isMobile) {
                target2.addEventListener('mouseenter', function (e) {
                    calculateAngle(e, target2Child.querySelector('.news-detail__3d-container'), this);
                })

                target2.addEventListener('mousemove', function (e) {
                    calculateAngle(e, target2Child.querySelector('.news-detail__3d-container'), this);
                })

                target2.addEventListener('mouseleave', function (e) {
                    let dropShadowColor = `rgba(0, 0, 0, 0.3)`
                    if (target2Child.getAttribute('data-filter-color') !== null) {
                        dropShadowColor = target2Child.getAttribute('data-filter-color')
                    }
                    target2Child.querySelector('.news-detail__3d-container').style.transform = `rotateY(0deg) rotateX(0deg) scale(1)`;
                    target2Child.querySelector('img').style.transform = `translate3d(0px, 0px, 0px)`;
                });

            }


            if ($card) {
                $card.forEach((item, index) => {

                    let oldScrollY;
                    document.addEventListener('scroll', function (e) {
                        let scrollY = window.scrollY;

                        let offset;
                        let prefix;
                        let pageYOffSet;
                        let counter;

                        if (scrollY < 1) {
                            pageYOffSet = document.body.scrollHeight
                        } else {
                            pageYOffSet = scrollY;
                        }
                        offset = pageYOffSet / document.body.scrollHeight;

                        if (oldScrollY < scrollY) {
                            prefix = "";
                            counter = counter - 1;
                        } else {
                            prefix = "-";
                            counter = counter + 1;
                        }

                        oldScrollY = scrollY;

                        if (offset === 1) {
                            offset = 0;
                        }

                        counter = prefix + 70 * offset;


                        // console.log(counter)
                        $card[index].style.perspective = `1247px`
                        $card[index].querySelector('.news-detail__3d-container').style.perspective = `1247px`
                        $card[index].querySelector('.news-detail__3d-container').style.transform = `rotateY(${counter * 2}deg) rotateX(${(counter * 1) / 1}deg) scale(1.04)`;
                        $card[index].querySelector('img').style.transform = `translate3d(${counter * (-3)}px, ${counter * (-1.5)}px, 60px)`;

                    });
                })
            }

        }
    },
}
</script>

<style>
.news-detail__wrapper {
    display: grid;
    grid-template-columns: 65% 35%;
    column-gap: 1rem;
    margin-top: 2rem;

}

.news-detail__title {
    font-size: 2.125rem;
    font-weight: 700;
    margin-bottom: 2.0625rem;
}

.news_detail__author-header__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
}

.news_detail__author-wrapper {
    display: flex;
}

.news_detail__author-header__social-media {
    display: flex;
}

.news_detail__author-header__social-media__container {
    margin-right: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
}

.news_detail__author-icon {
    margin-right: .8rem;
    width: 2.0625rem;
    height: 2.0625rem;
}

.news_detail__author-name {
    font-size: 0.875rem;
}

.news_detail__author-tag {
    font-size: 0.6rem;
}

.news_detail__author-tag::after {
    content: '|';
    width: .1rem;
    height: 1rem;
    margin: 0 .5rem;
}

.news_detail__author-tag:last-of-type::after {
    display: none;
}

.news-detail__content {
    overflow: hidden;
}

.news-detail__content img {
    max-width: 100%;
    perspective-origin: 0 0;
    transition: all 0.15s ease-out;
    width: 100vw;
    height: auto;
    object-fit: cover;
}

.news-detail__content video {
    max-width: 100%;
    height: auto;
}

.news-detail__content iframe {
    display: flex;
    max-width: 100% !important;
    width: 100%;
    height: 445px !important;
    border: none;
}

/* tag */
.news-detail__tags-wrapper {
    margin-bottom: 3.31rem;
}

.news-detail__tags-title__wrapper {
    border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.news-detail__tags {
    display: inline-block;
    background-color: var(--color-grey-60);
    padding: .4rem .5rem;
    font-size: 0.6875rem;
    border-radius: .4rem;
    margin-right: .5rem;
    margin-bottom: .8rem;
    cursor: pointer;
}

/* related news */
.news_latest-news__title-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: .1rem solid var(--color-grey-60);
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.news_latest-news__title {
    font-size: 0.875rem;
    font-weight: 700;
}

.news_latest-news__container {
    display: flex;
    padding-bottom: 1rem;
    border-bottom: .1rem solid var(--color-grey-60);
    margin-bottom: 1rem;

}

.news_latest-news__container-img {
    margin-right: 1.5rem;
    position: relative;
    min-width: 11.625rem;
    height: 6.563rem;
}

.news_latest-news__container-img__coverImg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.news_latest-news__container-img-icon {
    position: absolute;
    bottom: 0.67rem;
    left: 0.67rem;
    width: 1.87rem;
    height: 1.87rem;
}

.news_latest-news__container-img-icon img {
    width: 100%;
    height: 100%;
}

.news_latest-news__container-tag__wrapper {
    margin: .3rem 0;
}

.news_latest-news__container-tag {
    display: inline-block;
    background-color: var(--color-grey-60);
    padding: .4rem .5rem;
    font-size: 0.6875rem;
    border-radius: .4rem;
    margin-right: .5rem;
}

.news_latest-news__wrapper {
    display: grid;
    row-gap: 1rem
}

.news_latest-news__container-desc {
    padding: 1rem 1rem 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.news_latest-news__container-desc__title {
    font-weight: 700;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.875rem;
}

.news_latest-news__container-desc_tag {
    font-size: 0.6rem;
}

.news_latest-news__container-desc_tag::after {
    content: '|';
    width: .1rem;
    height: 1rem;
    margin: 0 .5rem;
}

.news_latest-news__container-desc_tag:last-of-type::after {
    display: none;
}

.news-detail__3d-container {
    width: 100%;
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color: #2933bd; */
    background-color: #DAE1EB;
    transform-style: preserve-3d;
    border-radius: 1rem;
    transition: all 0.15s ease-out;

    filter: drop-shadow(rgba(0, 0, 0, 0.3) -88.5228px 21.4436px 15px);

}

.news-detail__3d-wrapper {
    position: relative;
    width: 25rem;
    height: 25rem;
    height: inherit;
    display: block;
    margin: 3rem 6rem;
    border-radius: 1rem;
}

.news-detail__3d {
    position: absolute;
    transition: all .3s;
    transform-style: preserve-3d;

    height: inherit;
}

.news-detail__3d img {
    width: 100%;
    transform: translateZ(120px);
    height: inherit
}

.news-detail__3d-phones img {
    transform: translateZ(100px);
}

.news-detail__3d-1 {
    z-index: 2;
}

.news-detail__3d-3 {
    /* z-index: 1; */
}

.news-detail__3d-2 {
    z-index: 3;
    -webkit-animation: 5s moving_1 infinite linear;
}

.news-detail__3d-4 {
    -webkit-animation: 5s moving_1 infinite linear;
}

.news-detail__3d-5 {
    transform: translateZ(30px);
    -webkit-animation: 5s moving_2 infinite linear;
    z-index: 3;
}

.news-detail_3d-floating-1 {
    -webkit-animation: 4s moving_1 infinite linear;
}

.news-detail_3d-floating-2 {
    -webkit-animation: 5s moving_2 infinite linear;
}

.news-detail-3d-trophy {
    z-index: 10;
}

.news-detail__3d-btn {
    width: 115px;
    bottom: 2rem;
    left: 31%;
    border-radius: 1rem;
    position: relative;
    top: -5.5rem;
    display: block;
    perspective: 115px;
}

.news-detail__3d-btn__container {
    overflow: hidden;
    position: relative;
    background: linear-gradient(rgb(107 255 139), rgb(17, 155, 23));
    font-size: 1rem;
    color: white;
    padding: .8rem;
    line-height: 1rem;
    will-change: transform, filter;
    float: none;
    margin: 0;
    transition: all 0.15s ease-out;
    height: auto;
    border-radius: 100px;
    overflow: hidden;
    display: block;
    margin: 0px auto;
    display: block;
    transform: rotateX(0deg) rotateY(0deg) scale(1);
    filter: drop-shadow(0 15px 15px rgba(0, 0, 0, 0.3));
    font-weight: 500;
    perspective-origin: 0 0;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
}

.news-detail__3d-phone {
    width: 14rem;
    position: absolute;
    height: 9rem;
    top: 2rem;
    right: 0.5rem;
    z-index: 1;
}

.news-detail__3d-btn img {
    height: 100%;
}

.news-detail_3d-glowing {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 29rem;
    background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(1%, rgba(255, 255, 255, 0.03)), color-stop(30%, rgba(255, 255, 255, 0.85)), color-stop(50%, rgba(255, 255, 255, 0.85)), color-stop(70%, rgba(255, 255, 255, 0.85)), color-stop(71%, rgba(255, 255, 255, 0.85)), color-stop(100%, rgba(255, 255, 255, 0)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.03) 1%, rgba(255, 255, 255, 0.6) 30%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.85) 70%, rgba(255, 255, 255, 0.85) 71%, rgba(255, 255, 255, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#00ffffff", GradientType=1);
    width: 40%;
    height: 100%;
    transform: skew(-10deg, 0deg);
    -webkit-transform: skew(-10deg, 0deg);
    -moz-transform: skew(-10deg, 0deg);
    -ms-transform: skew(-10deg, 0deg);
    -o-transform: skew(-10deg, 0deg);
    animation: move 2s;
    animation-iteration-count: infinite;
    animation-delay: 1s;
    -webkit-animation: move 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-delay: 1s;
    -moz-transform: skew(-10deg, 0deg);
    -moz-animation: move 2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-delay: 1s;
    -ms-transform: skew(-10deg, 0deg);
    -ms-animation: move 2s;
    -ms-animation-iteration-count: infinite;
    -ms-animation-delay: 1s;
    -o-transform: skew(-10deg, 0deg);
    -o-animation: move 2s;
    -o-animation-iteration-count: infinite;
    -o-animation-delay: 1s;
}

.news-detail__3d-content {
    animation: zoom-in-zoom-out 2.5s ease infinite;
    z-index: 1;
}

.card {
    width: 400px;
    height: 200px;
    position: relative;
    background: #00BCD4;
    border-radius: 3px;
    background-position: top center;
    transform-style: preserve-3d;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem
}

.card:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 8px;
    border-radius: 50%;
    left: 0;
    bottom: 0px;
    box-shadow: 0 30px 20px rgb(0 0 0 / 30%);
}

.card-content {
    transform-style: preserve-3d;
    text-align: center;
    font-size: 1.5rem;
}

.card-content h1 {
    color: #FFF;
    transform: translateZ(100px);
}

.card-content p {
    color: #FFF;
    transform: translateZ(50px);
}

@-webkit-keyframes moving_1 {
    0% {
        transform: translatex(-3px)
    }

    25% {
        transform: translatex(3px)
    }

    50% {
        transform: translatey(-3px)
    }

    75% {
        transform: translatey(3px)
    }

    100% {
        transform: translatex(-3px)
    }
}

@keyframes moving_2 {
    0% {
        transform: translatex(3px)
    }

    25% {
        transform: translatex(-3px)
    }

    50% {
        transform: translatey(3px)
    }

    75% {
        transform: translatey(-3px)
    }

    100% {
        transform: translatex(3px)
    }
}

@keyframes move {
    0% {
        left: 0;
        opacity: 0.2;
    }

    5% {
        opacity: 0.25;
    }

    48% {
        opacity: 0.5;
    }

    80% {
        opacity: 0.1;
    }

    100% {
        left: 82%;
    }
}

@keyframes zoom-in-zoom-out {
    0% {
        transform: scale(1, 1);
    }

    50% {
        transform: scale(1.05, 1.05);
    }

    100% {
        transform: scale(1, 1);
    }
}

@media (max-width: 768px) {
    .news-detail__3d-wrapper {
        width: 100%;
        left: 0;
        /* height: 52vw; */
        margin: 0;
        box-sizing: border-box;
        padding: 2rem;
    }

    .news-detail__wrapper {
        display: flex;
        flex-direction: column;
    }

    .news-detail__title {
        font-size: 1.125rem;
    }

    .news_detail__author-name {
        font-weight: 700;
        margin-bottom: .2rem;
        display: block;
    }

    .news_latest-news__container {
        flex-direction: column;
        border-bottom: none;
    }

    .news_latest-news__container-desc {
        padding: 0.5rem 1rem;
        background-color: var(--color-theme)
    }

    .news_latest-news__container-desc__title {
        height: 2rem;
        font-weight: normal;
    }

    .news-detail__tags-wrapper {
        margin-bottom: 0.51rem;
    }

    .news_latest-news__title-wrapper {
        margin-bottom: 0;
        border-bottom: none;
    }

    .news_latest-news__container-img {
        margin-right: 0;
        width: auto;
        min-width: auto;
    }

    .news-detail__content iframe {
        height: 53vw !important;
    }

    .news-detail__3d-btn {
        bottom: 20px;
        left: 24vw;
        border-radius: 1rem;
        top: -2.5rem;
        width: 90px;
    }

    .news-detail__3d-btn__container {
        padding: .5rem;
    }

    .news-detail__3d-phone {
        width: 8rem;
    }
}
</style>